<template>
  <div>
    <div class="selection-title" v-if="title">
      <div>{{ title }}</div>
    </div>
    <el-cascader
      class="cascader"
      v-model="selectedOption"
      :options="options"
      :disabled="disabled"
      :data-testid="dataTestId"
    ></el-cascader>
  </div>
</template>

<script>
export default {
  name: 'Cascader',
  model: {
    prop: 'value',
    event: 'returnModel'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String | Array | Number,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    dataTestId: {
      type: String | Number,
      default: 'selection'
    }
  },
  computed: {
    selectedOption: {
      get() {
        return this.findPath(this.value, this.options) || [];
      },
      set(newValue) {
        const value = Array.isArray(newValue) ? newValue[newValue.length - 1] : newValue;
        this.$emit('returnModel', value);
      }
    }
  },
  methods: {
    // expect option list item has unique value
    findPath(value, options, path = []) {
      for (let option of options) {
        if (option.value === value) {
          return [...path, option.value];
        }
        if (option.children) {
          const foundPath = this.findPath(value, option.children, [...path, option.value]);
          if (foundPath) {
            return foundPath;
          }
        }
      }
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.selection {
  &-title {
    font-weight: 400;
    font-size: 16px;
    color: $pug-gray-title;
  }

  &.error {
    .selection-title {
      color: $pug-red;
      &.on-focus {
        color: $pug-red;
      }
    }
  }
}

.cascader {
  /deep/ &.el-cascader {
    width: 100%;

    .el-input .el-input__inner {
      border-radius: 0;
      border-style: solid;
      border-top: 0;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid $border-color;
      line-height: 30px;
      height: fit-content;
      font-size: 16px;
      font-weight: 700;
      color: #232020;
      padding: 0;
    }

    .el-input__icon.el-icon-arrow-down {
      font-size: 18px;
      font-weight: 900;
      color: #232020;
    }
  }
}
</style>
