<template>
  <div>
    <el-button type="primary" :disabled="btnDisabled" @click="clickFunc()">
      {{ title }}
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'TradingContestButton',
  props: {
    openAccountModalVisible: Boolean,
    eligibleAccountModalVisible: Boolean,
    tradingContestInfo: {
      type: Object,
      default: () => ({})
    },
    isAgreeTnc: Boolean
  },
  components: {},
  data() {
    return { showError: false };
  },
  computed: {
    status() {
      return this.tradingContestInfo.status;
    },
    title() {
      switch (this.status) {
        case 'JOIN NOW':
          return this.$t('promotion.tradingContest.optin');
        case 'Trade Now':
          return this.$t('promotion.tradingContest.trade');
        default:
          return this.$t('promotion.tradingContest.deposit');
      }
    },
    btnDisabled() {
      switch (this.status) {
        case 'Pending':
          return true;
        default:
          return false;
      }
    },
    eligibleAccountList() {
      if (this.tradingContestInfo.eligibleAccounts && this.tradingContestInfo.eligibleAccounts.length > 0) {
        return this.tradingContestInfo.eligibleAccounts;
      } else {
        return [];
      }
    }
  },
  methods: {
    clickFunc() {
      if (this.status == 'JOIN NOW') {
        if (this.isAgreeTnc) {
          this.showError = false;
          return this.joinContest();
        } else {
          this.$emit('update:showError', true);
        }
      } else {
        switch (this.status) {
          case 'Trade Now':
            return this.tradeNow();
          default:
            return this.depositNow();
        }
      }
    },
    depositNow() {
      this.$router.push({ path: '/depositFunds' });
    },
    tradeNow() {
      window.open('https://webtrader.puprime.com/', '_blank');
    },
    joinContest() {
      console.log(this.eligibleAccountList);
      if (this.eligibleAccountList.length > 0) {
        this.$emit('update:eligibleAccountModalVisible', true);
      } else {
        this.$emit('update:openAccountModalVisible', true);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.text-warn {
  font-size: 14px;
  color: $dark-magenta;
}
.el-button {
  background: #006bff;
  border-radius: 10px;
  font-weight: 600;
  padding: 16px 30px;
}

.el-button.is-disabled {
  background: #eeeeee;
  color: #bbbaba;
}
</style>
