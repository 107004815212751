<template>
  <div>
    <el-pagination
      class="page-box"
      :background="background"
      :page-size="pageSize"
      :page-sizes="pageSizes"
      :layout="layout"
      :total="total"
      :pager-count="pagerCount"
      :current-page="currentPage"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      @prev-click="handlePrevClick"
      @next-click="handleNextClick"
      data-testid="pagination"
    ></el-pagination>
  </div>
</template>

<script>
export default {
  props: {
    background: {
      type: Boolean,
      default: true
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 25, 50, 100];
      }
    },
    layout: {
      type: String,
      default: 'total, sizes, pager, prev, next'
    },
    total: {
      type: Number,
      required: true
    },
    pagerCount: {
      type: Number,
      default: 5 // if less than 5 will hit validation error
    },
    // must provide pageNumber and pageSize
    query: {
      type: Object,
      default: () => ({
        pageSize: 10,
        pageNumber: 0
      })
    }
  },
  data() {
    return {};
  },
  computed: {
    pageSize() {
      return this.query.pageSize;
    },
    currentPage() {
      return this.query.pageNumber + 1;
    }
  },
  watch: {
    query: {
      handler() {
        this.$emit('refreshData');
      },
      deep: true
    }
  },
  methods: {
    handleCurrentChange(pageNumber) {
      this.query.pageNumber = pageNumber - 1;
    },
    handleSizeChange(size) {
      this.query.pageSize = size;
    },
    handlePrevClick() {
      this.query.pageNumber = this.query.pageNumber - 1;
    },
    handleNextClick() {
      this.query.pageNumber = this.query.pageNumber + 1;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/pagination.scss';
</style>
