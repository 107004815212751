<template>
  <el-dialog :visible.sync="visible" top="0" :title="$t('promotion.tradingContest.prizes')">
    <div>
      <div class="content">
        <div class="prizes_top3">
          <div class="prize_box" v-for="(item, i) in prizeList1" :key="i">
            <div class="ranking">{{ formatRank(parseInt(item.rank)) }}</div>
            <div class="title">USD${{ numberFormatter(item.amount) }}</div>
            <div class="ribbon">
              <img src="@/assets/images/promotion/tradingContest/prize_ribbon.png" />
            </div>
          </div>
        </div>
        <div class="prizes_remaining">
          <div v-for="(item, i) in prizeList2" :key="i">
            <div class="group">
              <div class="ranking">{{ formatRank(parseInt(item.rank)) }}</div>
              <div class="title">USD${{ numberFormatter(item.amount) }}</div>
            </div>
            <el-divider />
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getOrdinal } from '@/util/ordinal';
export default {
  name: 'PrizePopup',
  props: {
    prizePopupVisible: Boolean,
    prizeList1: {
      type: Array,
      default() {
        return [];
      }
    },
    prizeList2: {
      type: Array,
      default() {
        return [];
      }
    }
  },

  data() {
    return {
      visible: false
    };
  },
  watch: {
    prizePopupVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:prizePopupVisible', bool);
    }
  },
  methods: {
    numberFormatter(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    },
    formatRank(value) {
      return getOrdinal(value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/tradingContest/prizePopup.scss';
</style>
