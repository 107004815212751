<template>
  <div>
    <el-dialog :visible.sync="visible" top="0" class="open_account_modal">
      <div class="title">{{ $t('verificationStatus.accOpeningVerification.title') }}</div>
      <div class="sub-title">
        <i18n path="verificationStatus.accOpeningVerification.subTitle">
          <template v-slot:link>
            <a href="https://puprime.com/account-types" target="_blank">{{
              $t('verificationStatus.accOpeningVerification.here')
            }}</a>
          </template>
        </i18n>
      </div>
      <el-form :model="form" :rules="formRules" ref="form" label-position="top" style="padding-top: 10px">
        <el-row :gutter="40">
          <el-col :sm="12" :xs="24">
            <el-form-item prop="tradingPlatform">
              <Selection
                v-model="form.tradingPlatform"
                :placeholder="$t('common.formValidation.answerReq')"
                :options="tradingPlatformOptions"
                filterable
                selectType="underline"
                dataTestId="platform"
              >
                <template #title>
                  {{
                    $t('register.accOpeningVerification.createLiveAccount.choose', {
                      dynamic: $t('register.accOpeningVerification.createLiveAccount.tradingPlatform')
                    })
                  }}
                </template>
              </Selection>
            </el-form-item>
          </el-col>
          <el-col :sm="12" :xs="24">
            <el-form-item prop="accountType">
              <Cascader
                :title="
                  $t('register.accOpeningVerification.createLiveAccount.choose', {
                    dynamic: $t('register.accOpeningVerification.createLiveAccount.accountType')
                  })
                "
                v-model="accountTypeSelection"
                :options="accountTypeList"
              ></Cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="40">
          <el-col :sm="12" :xs="24">
            <el-form-item prop="currency">
              <Selection
                v-model="form.currency"
                :placeholder="$t('common.formValidation.answerReq')"
                :options="currencyOptions"
                filterable
                selectType="underline"
                dataTestId="currency"
              >
                <template #title>
                  {{
                    $t('register.accOpeningVerification.createLiveAccount.choose', {
                      dynamic: $t('register.accOpeningVerification.createLiveAccount.accountCurrency')
                    })
                  }}
                </template>
              </Selection>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="40">
          <el-col class="mb-4" v-if="minDeposit || isIslamicAccountType">
            <div class="info-box">
              <div class="info-img">
                <img src="@/assets/images/info-icon.png" class="label-icon" />
              </div>
              <div class="info-box-text">
                <div v-if="minDeposit">
                  <span>{{ $t('openAdditAcc.minDeposit') }}</span>
                  <span
                    ><span class="text-higlight">{{ minDeposit }}</span> {{ form.currency }}</span
                  >
                </div>
                <div v-if="isIslamicAccountType">
                  <div v-if="isIslamic">
                    <strong>{{ $t('openAdditAcc.importantNote') }}</strong>
                    <span v-html="$t('openAdditAcc.islamicTnc')"></span>
                  </div>
                  <div v-else>
                    <strong>{{ $t('openAdditAcc.importantNote') }}</strong>
                    <span>
                      <i18n path="openAdditAcc.nonIslamicTnc">
                        <template v-slot:link>
                          <a
                            href="https://helpcenter.puprime.com/hc/en-001/articles/5551547411983-When-would-an-administration-fee-be-charged-in-my-Islamic-Trading-Account"
                            target="_blank"
                            >{{ $t('openAdditAcc.here') }}</a
                          >
                        </template>
                      </i18n>
                    </span>
                  </div>
                  <div>
                    <span>{{
                      $t('register.accOpeningVerification.createLiveAccount.islamicTooltip', {
                        platform: $config.info.fullName
                      })
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
          <el-col>
            <el-form-item class="mb-3" prop="checkStatus" :inline-message="true">
              <div class="checkBox pug-checkbox">
                <div class="d-flex align-items-center">
                  <el-checkbox id="checkbox" name="checkStatus" v-model="form.checkStatus" data-testid="checkStatus">
                  </el-checkbox>
                  <i18n path="openAdditAcc.byTicking" tag="div" class="tnc">
                    <template v-slot:tnc>
                      <p class="showTnc" @click="showTNC = !showTNC" data-testid="showTNC">
                        {{ $t('openAdditAcc.tnc') }}
                      </p>
                    </template>
                  </i18n>
                </div>
                <ul
                  class="tnc-details"
                  v-show="showTNC"
                  v-html="
                    $t($config.openLiveAccount.getAgreeCondition(regulator), {
                      platform: $config.info.fullName,
                      regulatorUrl: GLOBAL_REFERRAL_SITE
                    })
                  "
                ></ul>
              </div>
            </el-form-item>
            <div v-if="showCopyTradingTnc">
              <el-form-item class="mb-3" prop="checkCopyTradingTnc" :inline-message="true">
                <div class="checkBox pug-checkbox">
                  <div class="d-flex align-items-center">
                    <el-checkbox
                      id="checkbox"
                      name="checkCopyTradingTnc"
                      v-model="form.checkCopyTradingTnc"
                      data-testid="checkCopyTradingTnc"
                    >
                    </el-checkbox>
                    <i18n path="openAdditAcc.byTickingCopyTrading" tag="div" class="tnc">
                      <template v-slot:tnc>
                        <a class="showTnc" target="_blank" :href="copyTradingHref" data-testid="showCopyTradingTnc">{{
                          $t('openAdditAcc.tnc')
                        }}</a>
                      </template>
                    </i18n>
                  </div>
                </div>
              </el-form-item>
            </div>
          </el-col>
        </el-row>
      </el-form>
      <div class="btn_box">
        <el-button class="secondary" @click="handleClose">{{ $t('promotion.tradingContest.back') }}</el-button>
        <el-button class="primary" @click="submitClick">{{ $t('promotion.tradingContest.submit') }}</el-button>
      </div>
    </el-dialog>
    <DialogVersion3 :visible="pammVisible" extraClass="pamm-dialog">
      <div class="dialog-body text-center">
        <p><span class="required_icon">*</span>{{ $t('openAdditAcc.pamm') }}</p>
        <div class="line"></div>
        <p class="red">*{{ $t('openAdditAcc.pammConfirm') }}</p>
        <div class="btn-box">
          <el-button class="btn-default" @click="isShowDialog = false">
            {{ $t('common.button.confirm') }}
          </el-button>
          <el-button class="btn-blue" @click="resetType()">{{ $t('common.button.cancel') }}</el-button>
        </div>
      </div>
    </DialogVersion3>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import {
  apiApplyTradingContestAccount,
  apiTradingContestOpt,
  apiGetAutoStartTradingThreshold,
  apiQueryMetaTraderAccountDetails
} from '@/resource';
import { checkAgreedTNC, checkAgreedCopyTradingTNC } from '@/util/validation';
import Selection from '@/components/common/Selection.vue';
import Cascader from '@/components/common/Cascader.vue';
import { islamicCountry } from '@/constants/islamicCountry';
import { highRiskCountry } from '@/constants/highRiskCountry';
import DialogVersion3 from '@/components/dialog/v3/Dialog';

export default {
  name: 'OpenAccountModal',
  props: {
    openAccountModalVisible: Boolean,
    tradingContestInfo: Object
  },
  components: {
    Selection,
    Cascader,
    DialogVersion3,
    Button
  },
  data() {
    return {
      visible: false,
      form: {
        tradingPlatform: null,
        accountType: null,
        currency: null,
        checkStatus: false
      },
      checkStatus: false,
      showTNC: false,
      formRules: {
        tradingPlatform: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.createLiveAccount.tradingPlatform')
            }),
            trigger: 'submit'
          }
        ],
        accountType: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.createLiveAccount.accountType')
            }),
            trigger: 'submit'
          }
        ],
        currency: [
          {
            required: true,
            message: this.$t('common.formValidation.dynamicReq', {
              dynamic: this.$t('register.accOpeningVerification.createLiveAccount.accountCurrency')
            }),
            trigger: 'submit'
          }
        ],
        checkStatus: [{ validator: checkAgreedTNC, trigger: 'change' }],
        checkCopyTradingTnc: [{ validator: checkAgreedCopyTradingTNC, trigger: 'change' }]
      },
      mt4AccountId: '',
      accountTypeSelection: null,
      copyTradingHref: '',
      isShowDialog: true,
      threshold: {},
      isExistCopyTrading: false
    };
  },
  computed: {
    countryCode() {
      return this.$store.state.common.countryCode;
    },
    tradingPlatformOptions() {
      const accountTypes = this.tradingContestInfo.eligibleAccountType;
      let result = this.$config.openLiveAccount.choosePlatform(this.regulator);

      if (accountTypes?.mt4 && accountTypes.mt4.length === 0) {
        result = result.filter(item => item.value != 'mt4');
      }

      if (accountTypes?.mt5 && accountTypes.mt5.length === 0) {
        result = result.filter(item => item.value != 'mt5');
      }

      if ((accountTypes?.mts && accountTypes.mts.length === 0) || this.isExistCopyTrading) {
        result = result.filter(item => item.value != 'MTS');
      }

      if (result) this.form.tradingPlatform = result[0].value;

      return result.map(item => ({
        label: this.$t(item.label),
        value: item.value
      }));
    },
    accountTypeList() {
      if (this.currencyOptions.length === 0) return [];
      if (this.form.tradingPlatform === 'mt4') {
        return this.showMT4LiveType;
      } else if (this.form.tradingPlatform === 'mt5') {
        return this.showMT5LiveType;
      } else if (this.form.tradingPlatform === 'MTS') {
        return this.showMTSLiveType;
      }
    },
    currencyOptions() {
      let result = this.$config.openLiveAccount.chooseCurrency(this.regulator, this.form.accountType);
      if (this.tradingContestInfo.eligibleCurrency) {
        result = result.filter(type => this.tradingContestInfo.eligibleCurrency.includes(type.currency));
        this.$nextTick(() => {
          this.form.currency = result.length > 0 ? result[0].value : '';
        });
        return result;
      }
      return [];
    },
    showMT4LiveType() {
      return this.getLiveTypeOptions('mt4');
    },
    showMT5LiveType() {
      return this.getLiveTypeOptions('mt5');
    },
    showMTSLiveType() {
      return this.getLiveTypeOptions('mts');
    },
    minDeposit() {
      try {
        return this.threshold[this.form.accountType][this.form.currency].amount;
      } catch (e) {
        return null;
      }
    },
    showCopyTradingTnc() {
      this.copyTradingHref = 'https://www.puprime.com/copy_trading_terms_and_conditions_cp';

      if (highRiskCountry.includes(this.countryCode)) {
        this.copyTradingHref = 'https://www.puprime.net/copy_trading_terms_and_conditions_cp_net';
      }

      if (this.form.tradingPlatform === 'MTS') {
        return true;
      } else {
        return false;
      }
    },
    isIslamic() {
      return islamicCountry.includes(this.countryCode) ? true : false;
    },
    isIslamicAccountType() {
      const islamicAccountType = ['swapFreeStandardSTP', 'ISLAMIC_STP_CENT', 'swapFreeRawECN', 'ISLAMIC_ECN_CENT'];

      return islamicAccountType.includes(this.form.accountType) ? true : false;
    },
    pammVisible() {
      return this.isShowDialog && this.form.accountType === 'PAMM';
    }
  },
  watch: {
    openAccountModalVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:openAccountModalVisible', bool);
    },
    'form.accountType': {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (oldVal) this.form.currency = '';
      }
    },
    accountTypeSelection: {
      immediate: true,
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (Array.isArray(newVal)) {
            this.form.accountType = newVal[newVal.length - 1];
          } else {
            this.form.accountType = newVal;
          }

          if (newVal === 'PAMM') {
            this.isShowDialog = true;
          }
        }
      }
    }
  },
  methods: {
    isValidByCountry(obj, countryCode) {
      if (obj.restrictCountries) {
        return !obj.restrictCountries.includes(countryCode);
      }
      return true;
    },
    filterChildren(children, countryCode) {
      return children.filter(child => this.isValidByCountry(child, countryCode));
    },
    handleClose() {
      this.visible = !this.visible;
    },
    createSuccess() {
      this.$emit('openAccountSuccess');
      this.handleClose();
    },
    postApiApplyAdditionalAccount() {
      apiApplyTradingContestAccount({
        accountType: this.form.accountType,
        tradingPlatform: this.form.tradingPlatform,
        currency: this.form.currency
      })
        .then(resp => {
          if (resp.data.code === 0) {
            this.mt4AccountId = resp.data.data.id;
            this.postApiCampaignOpt();
          }
        })
        .catch(err => {
          this.$message({
            message: this.$t('responseMsg.500'),
            type: 'error'
          });
        });
    },
    postApiCampaignOpt() {
      apiTradingContestOpt({
        campaignId: this.$store.state.promotion.tradingContest.tradingContestId,
        optIn: true,
        mt4AccountId: this.mt4AccountId
      })
        .then(resp => {
          if (resp.data.code === 0 && resp.data.data) this.createSuccess();
          else {
            this.$message({
              message: this.$t('responseMsg.500'),
              type: 'error'
            });
          }
        })
        .catch(err => {
          this.$message({
            message: this.$t('responseMsg.500'),
            type: 'error'
          });
        });
    },
    getAutoStartTradingThreshold() {
      apiGetAutoStartTradingThreshold().then(resp => {
        resp.data.forEach(element => {
          if (!this.threshold[element.alias])
            Object.assign(this.threshold, {
              [element.alias]: {
                [element.currency]: {
                  amount: element.threshold
                }
              }
            });
          else if (!this.threshold[element.alias][element.currency])
            Object.assign(this.threshold[element.alias], {
              [element.currency]: {
                amount: element.threshold
              }
            });
        });
      });
    },
    submitClick() {
      this.$refs['form'].validate(valid => {
        if (valid) this.postApiApplyAdditionalAccount();
      });
    },
    resetType() {
      if (
        this.accountTypeList.length > 0 &&
        Array.isArray(this.accountTypeList[0].children) &&
        this.accountTypeList[0].children.length > 0
      ) {
        this.accountTypeSelection = this.accountTypeList[0]?.children[0]?.value;
      } else {
        this.accountTypeSelection = this.accountTypeList[0]?.value;
      }
    },
    queryMetaTraderAccountDetails() {
      apiQueryMetaTraderAccountDetails({}).then(resp => {
        if (resp.data?.length > 0) {
          let result = resp.data;
          this.isExistCopyTrading = result.filter(item => item.serverCategory === '6').length > 0;
        }
      });
    },
    getLiveTypeOptions(accountTypeKey) {
      const accountTypes = this.tradingContestInfo.eligibleAccountType;
      const typeList = this.$config.openLiveAccount.additionalAccountType;

      // Filter for restricted countries
      let result = typeList(this.regulator).filter(f => {
        const isParentValid = this.isValidByCountry(f, this.countryCode);

        if (f.children && f.children.length > 0) {
          f.children = this.filterChildren(f.children, this.countryCode);
        }

        return isParentValid || (f.children && f.children.length > 0);
      });

      // Filter for eligibleAccountType (Backend return)
      if (accountTypes && accountTypes[accountTypeKey]) {
        result = result.reduce((filtered, type) => {
          const isParentIncluded = accountTypes[accountTypeKey].includes(type.value);

          const includedChildren =
            type.children && type.children.length > 0
              ? type.children.filter(child => accountTypes[accountTypeKey].includes(child.value))
              : [];

          if (isParentIncluded || includedChildren.length > 0) {
            filtered.push({
              ...type,
              children: includedChildren.length > 0 ? includedChildren : type.children
            });
          }

          return filtered;
        }, []);
      }

      return result;
    }
  },
  mounted() {
    this.getAutoStartTradingThreshold();
    this.queryMetaTraderAccountDetails();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/tradingContest/openAccountModal.scss';

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sub-title {
  margin-bottom: 20px;

  /deep/ a {
    color: $pug-blue;
    text-decoration: underline;
  }
}

.liveaccount-box {
  border-radius: 8px;
  background-color: $white;
  max-width: 1200px;
  padding: 68px 40px;

  /deep/ .success {
    max-width: 904px;

    .steps {
      margin-bottom: 80px;

      img {
        margin-bottom: 0;
        display: inline-block;
      }

      .line {
        display: inline-block;
        border-width: $border-width;
        border-color: $blue;
        border-style: dashed;
        width: 157px;
        margin: 0 20px 0;
      }
    }

    .success-content {
      max-width: 600px;

      p {
        font-size: 15px;
        color: $dark-gray;
        line-height: 1.667;
        text-align: center;
      }
    }
  }

  @media screen and (min-width: 576px) {
    padding: 68px 120px;
  }
}

.checkBox {
  /deep/ .el-checkbox {
    display: contents;
  }

  .tnc-details {
    margin-top: 10px;
  }

  /deep/ .tnc {
    font-size: 14px;
    line-height: 21px;
    display: inline-block;
    @include rtl-sass-prop(margin-left, margin-right, 15px);

    .showTnc {
      display: inline-block;
      color: $pug-blue;
      cursor: pointer;
    }
  }
  /deep/ ul {
    @include rtl-sass-prop(padding-left, padding-right, 40px);

    li {
      font-size: 14px;
      line-height: 28px;
      list-style-type: decimal;
      a {
        color: $primary;
        text-decoration: none;
      }
    }
  }
}

.action-area {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 24px;
}

.action-button {
  width: 160px;
  height: 56px;
}

@media (max-width: 770px) {
  .action-area {
    flex-direction: column-reverse;
    gap: 16px;
  }
  .action-button {
    width: 100%;
  }
}

.text-higlight {
  color: $pug-blue;
}

.mr-2 {
  margin-right: 10px;
}

.info-box {
  display: flex;
  border-radius: 8px;
  background-color: #ebf4ff;
  padding: 14px 12px;
  align-items: center;

  /deep/ strong {
    font-weight: bold;
  }
}

.mb-0 {
  margin-bottom: 0;
}

.info-img {
  margin-right: 10px;
  flex-shrink: 0;
  align-self: flex-start;
}

.info-box-text {
  div + div {
    margin-top: 20px;
  }
}

/deep/ .pamm-dialog {
  .el-dialog {
    width: 680px;
  }

  .el-dialog__header {
    margin: 0;
  }

  .el-dialog__body {
    padding: 50px 50px 45px !important;

    p {
      font-size: 15px;
      color: #626d71;
      line-height: 1.8;
      word-break: normal;

      &.red {
        color: $red;
        margin-bottom: 40px;
      }
    }

    .line {
      border-width: 0.59px;
      border-color: rgb(160, 160, 160);
      border-style: dashed;
      margin: 20px 0;
    }

    .btn-box {
      display: grid;
      grid-template-columns: 45% 45%;
      column-gap: 10%;
      margin-bottom: 20px;
    }
  }
}
</style>
