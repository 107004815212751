<template>
  <div class="tncWrapper">
    <el-checkbox v-model="agreeTnc" data-testid="checkbox" class="agreeTnc pb-1">
      <i18n :path="agreeTncPath">
        <template v-slot:link>
          <a :href="tncUrl" target="_blank" data-testid="tnc" style="color: blue; text-decoration: none"
            >{{ $t(tncString) }}
          </a>
        </template>
      </i18n>
    </el-checkbox>
    <div v-if="showError" class="text-warn pt-1">{{ $t('common.formValidation.tnc') }}</div>
  </div>
</template>

<script>
export default {
  name: 'TermsAndConditions',
  props: {
    tncUrl: String,
    isAgreeTnc: Boolean,
    showError: Boolean,
    tncString: {
      type: String,
      default: 'promotion.tradingContest.tnc'
    },
    agreeTncPath: { type: String, default: 'promotion.tradingContest.agreeText' }
  },
  data() {
    return { agreeTnc: false };
  },
  watch: {
    isAgreeTnc(boolean) {
      this.agreeTnc = boolean;
    },
    agreeTnc(bool) {
      this.$emit('update:isAgreeTnc', bool);
      if (bool) this.$emit('update:showError', !bool);
    }
  }
};
</script>

<style lang="scss" scoped>
.tncWrapper {
  padding-bottom: 10px;
  a {
    font-weight: 400;
    color: $main-text;
    text-decoration: underline;
    padding: 0 5px;
    &:hover {
      text-decoration: underline !important;
      color: $orange-3;
    }
  }
  .el-checkbox {
    height: 14px;
  }
  .text-warn {
    color: $dark-magenta;
    font-size: 14px;
  }
  /deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $main-text;
  }
  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: $orange-1;
    border-color: $orange-1;
    color: $white;
    &:hover {
      background-color: $orange-1;
      border-color: $orange-1;
    }
    &::after {
      border-color: $white;
      color: $white;
    }
  }

  /deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
    border-color: $orange-1;
  }
  /deep/ .el-checkbox__input .el-checkbox__inner:hover {
    border-color: $orange-1;
  }
}
</style>
