<template>
  <el-dialog
    :visible.sync="modalVisible"
    :visibleFooter="true"
    top="0"
    :title="$t('promotion.tradingContest.column.accountNo')"
    class="eligible_account_modal"
  >
    <el-form :model="form" :rules="rules" ref="ruleForm">
      <SelectForm
        v-model="form.eligibleAccountId"
        name="eligibleAccountId"
        size="small"
        :placeholder="$t('common.field.accNum')"
        class="l-select"
      >
        <el-option
          v-for="item in eligibleAccountList"
          :key="item.id"
          :value="item.id"
          :label="item.mt4Account"
        ></el-option>
      </SelectForm>
    </el-form>

    <div class="btn_box">
      <el-button class="secondary" @click="handleClose">{{ $t('promotion.tradingContest.back') }}</el-button>
      <el-button class="primary" @click="submitClick">{{ $t('promotion.tradingContest.submit') }}</el-button>
    </div>

    <div class="cs-chat-container" @click="showLiveChat">
      <img src="@/assets/images/dialog/cs-chat.png" class="cs-chat-image" alt="cs-chat" />
      <div class="cs-chat-desc" v-html="$t('common.liveChat.desc')"></div>
    </div>
  </el-dialog>
</template>

<script>
import SelectForm from '@/components/form/Select';
import { apiTradingContestOpt } from '@/resource';

export default {
  name: 'EligibleAccountModal',
  props: {
    eligibleAccountModalVisible: Boolean,
    tradingContestInfo: Object
  },
  components: { SelectForm },
  data() {
    return {
      modalVisible: false,
      form: {
        eligibleAccountId: null
      },
      rules: {
        eligibleAccountId: [{ required: true, trigger: 'blur', message: this.$t('common.formValidation.accReq') }]
      }
    };
  },
  computed: {
    eligibleAccountList() {
      if (this.tradingContestInfo?.eligibleAccounts && this.tradingContestInfo?.eligibleAccounts?.length > 0) {
        return this.tradingContestInfo.eligibleAccounts;
      } else {
        return [];
      }
    }
  },
  watch: {
    eligibleAccountModalVisible(bool) {
      this.modalVisible = bool;
    },
    modalVisible(bool) {
      this.$emit('update:eligibleAccountModalVisible', bool);
    }
  },
  methods: {
    handleClose() {
      this.modalVisible = !this.modalVisible;
    },
    postApiCampaignOpt() {
      apiTradingContestOpt({
        campaignId: this.$store.state.promotion.tradingContest.tradingContestId,
        optIn: true,
        mt4AccountId: this.form.eligibleAccountId
      })
        .then(resp => {
          if (resp.data.code === 0 && resp.data.data) {
            this.handleClose();
            this.$emit('getDetails');
          } else {
            this.$message({
              message: this.$t('responseMsg.500'),
              type: 'error'
            });
          }
        })
        .catch(err => {
          this.$message({
            message: this.$t('responseMsg.500'),
            type: 'error'
          });
        });
    },

    submitClick() {
      this.$refs['ruleForm'].validate(valid => {
        if (valid) this.postApiCampaignOpt();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/tradingContest.scss';
@import '@/assets/css/pages/promotion/tradingContest/eligibleAccountModal.scss';
</style>
