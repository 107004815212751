<template>
  <div class="leaderboard">
    <div class="header">{{ $t('promotion.tradingContest.leaderboard') }}</div>
    <div class="desc">
      <span>{{ $t('promotion.tradingContest.leaderboardDesc1') }}&nbsp;</span>
      <span v-if="lastUpdate">{{
        $t('promotion.tradingContest.leaderboardDesc2', {
          dateTime: lastUpdate
        })
      }}</span>
    </div>

    <el-card shadow="never">
      <div class="table-box">
        <el-table class="mb-3" :data="displayData" style="width: 100%">
          <el-table-column prop="rank" :label="$t('promotion.tradingContest.column.rank')" min-width="130">
            <template slot-scope="scope">{{ scope.row.rank ? scope.row.rank : '-' }}</template>
          </el-table-column>
          <el-table-column prop="realName" :label="$t('promotion.tradingContest.column.name')" min-width="200">
            <template slot-scope="scope">{{ scope.row.realName }}</template>
          </el-table-column>
          <el-table-column
            prop="mt4AccountMask"
            :label="$t('promotion.tradingContest.column.accountNo')"
            min-width="140"
          >
            <template slot-scope="scope">{{ scope.row.mt4AccountMask }}</template>
          </el-table-column>
          <el-table-column prop="country" :label="$t('promotion.tradingContest.column.country')" min-width="140">
            <template slot-scope="scope">{{ scope.row.country }}</template>
          </el-table-column>
          <el-table-column :label="totalProfitColumn.label" min-width="140">
            <template slot-scope="scope">{{ totalProfitColumn.value(scope) }}</template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
    <PaginationWithApi :query.sync="query" :total="total" @refreshData="refreshData"></PaginationWithApi>
  </div>
</template>

<script>
import PaginationWithApi from '@/components/PaginationWithApi.vue';
import { apiGetTradingContestUsers } from '@/resource';
import { formatDateTime } from '@/util/time';

export default {
  name: 'Leaderboard',
  components: { PaginationWithApi },
  props: {
    tradingContestInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      displayData: [],
      query: {
        campaignId: this.$route.params.campaignId,
        pageNumber: 0,
        pageSize: 10
      },
      lastUpdateDate: '',
      campaignEnded: false,
      total: 0
    };
  },
  computed: {
    lastUpdate() {
      return this.tradingContestInfo.lastRankCalculate
        ? formatDateTime(this.tradingContestInfo.lastRankCalculate, 'DD/MM/YYYY HH:mm:ss')
        : null;
    },
    totalProfitColumn() {
      if (!this.tradingContestInfo) return;
      switch (this.tradingContestInfo.sortBy) {
        case 'Total Profit (USD)':
          return {
            label: this.$t('promotion.tradingContest.column.profit', {
              usedSymbol: this.$t('promotion.tradingContest.column.usd')
            }),
            value: scope =>
              scope.row.totalProfit
                ? scope.row.totalProfit.toLocaleString(undefined, { minimumFractionDigits: 2 })
                : '0.00'
          };
        default:
          return {
            label: this.$t('promotion.tradingContest.column.profit', { usedSymbol: '%' }),
            value: scope => (scope.row.totalPercentage ? this.truncate(scope.row.totalPercentage, 2) : '0.00')
          };
      }
    }
  },
  methods: {
    truncate(num, decimals) {
      const truncated =
        num < 0
          ? Math.ceil(num * Math.pow(10, decimals)) / Math.pow(10, decimals)
          : Math.floor(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
      return truncated.toFixed(decimals);
    },
    getTradingContestUsers() {
      apiGetTradingContestUsers(this.query).then(resp => {
        if (resp.data.code == 0) {
          this.total = resp.data.totalRecords;
          this.displayData = resp.data.data;
        }
      });
    },
    refreshData() {
      this.getTradingContestUsers();
    }
  },
  mounted() {
    this.getTradingContestUsers();
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/tradingContest/leaderboard.scss';
</style>
