<template>
  <div class="contest_account relative" :class="divider_class" v-if="tradingContestInfo.status != 'JOIN NOW'">
    <el-row type="flex">
      <el-col>
        <div class="accDiv">
          <p class="accountNo" v-html="$t('promotion.tradingContest.contestAcc')"></p>
          <p class="highlight">{{ accountNo }}</p>
        </div>
      </el-col>
      <el-col>
        <div class="detailDiv" v-if="tradingContestInfo.status == 'Trade Now'">
          <div class="rank">
            <div class="title">{{ $t('promotion.tradingContest.rank') }}</div>
            <div class="value">{{ participantAcc?.rank ? participantAcc.rank : '-' }}</div>
          </div>
          <div class="profit">
            <div class="title">{{ $t('promotion.tradingContest.profit') }}</div>
            <div class="value">
              {{ profit }}
            </div>
          </div>
        </div>

        <div class="status" v-if="tradingContestInfo.status == 'Pending'">
          <div class="status_title">{{ $t('promotion.tradingContest.statusTitle') }}</div>
          <div class="pending">
            {{ $t('promotion.tradingContest.status.pending.title') }}
            <el-popover
              :popper-class="`info-popover-content-wrapper`"
              placement="bottom-end"
              trigger="hover"
              :offset="80"
              :width="200"
            >
              <img slot="reference" src="@/assets/images/promotion/tradingContest/information.png" />
              <div class="popover_text" v-html="$t('promotion.tradingContest.status.pending.tooltip')"></div>
            </el-popover>
          </div>
        </div>

        <div class="status" v-if="tradingContestInfo.status == 'Ineligible'">
          <div class="status_title">{{ $t('promotion.tradingContest.statusTitle') }}</div>
          <div class="not_eligible">
            {{ $t('promotion.tradingContest.status.noteligible.title') }}
            <el-popover
              :popper-class="`info-popover-content-wrapper`"
              placement="bottom-end"
              trigger="hover"
              :offset="80"
              :width="200"
            >
              <img slot="reference" src="@/assets/images/promotion/tradingContest/information.png" />
              <div
                class="popover_text"
                v-html="
                  $t('promotion.tradingContest.status.noteligible.tooltip', {
                    amount: numberFormatter(tradingContestInfo.minimumDepositInUsd)
                  })
                "
              ></div>
            </el-popover>
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'AccountDetailBox',
  props: {
    tradingContestInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      pending: false,
      notEligible: false
    };
  },
  computed: {
    participantAcc() {
      return this.tradingContestInfo.participantAccount.length ? this.tradingContestInfo.participantAccount[0] : null;
    },
    sortBy() {
      return this.tradingContestInfo.sortBy;
    },
    profit() {
      if (this.participantAcc) {
        if (this.sortBy == 'Total Profit (USD)' && this.participantAcc.totalProfit)
          return this.participantAcc.totalProfit;
        if (this.sortBy == 'Total Profit (%)' && this.participantAcc.totalPercentage)
          return this.participantAcc.totalPercentage;
      }

      return '-';
    },
    accountNo() {
      return this.$store.state.promotion.tradingContest.tradingContestAcc
        ? this.$store.state.promotion.tradingContest.tradingContestAcc
        : 'NA';
    },
    divider_class() {
      switch (this.tradingContestInfo.status) {
        case 'JOIN NOW':
        case 'Trade Now':
          return 'divider_3';
        default:
          return 'divider_2';
      }
    }
  },
  methods: {
    numberFormatter(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/tradingContest/accDetailBox.scss';
</style>
