<template>
  <div class="container">
    <div class="promotion">
      <el-card shadow="never">
        <div class="grid-container">
          <div class="left">
            <el-image class="mb-3 image-border-radius" :src="clientPortalSettings.document" :fit="'contain'" />
            <div class="text_wrap">
              <p class="text vhtml_component" v-html="clientPortalSettings.description1"></p>
            </div>
          </div>
          <div class="right">
            <div class="bg-gray">
              <div
                class="text vhtml_component"
                v-html="status == 'JOIN NOW' ? clientPortalSettings.description2 : clientPortalSettings.description3"
              ></div>
              <AccountDetailBox :tradingContestInfo="tradingContestInfo" :status="status" />
              <div class="mt-4">
                <TermsAndConditions
                  :isAgreeTnc.sync="isAgreeTnc"
                  :tncUrl="clientPortalSettings.tncLink"
                  :showError.sync="showCheckboxError"
                  v-if="status == 'JOIN NOW' && showButton"
                />
                <TradingContestButton
                  v-if="showButton"
                  :class="status !== 'JOIN NOW' ? 'mt-4' : ''"
                  :tradingContestInfo="tradingContestInfo"
                  :openAccountModalVisible.sync="openAccountModalVisible"
                  :eligibleAccountModalVisible.sync="eligibleAccountModalVisible"
                  :isAgreeTnc.sync="isAgreeTnc"
                  :showError.sync="showCheckboxError"
                />
                <div v-if="status !== 'JOIN NOW'">
                  <div class="mt-3" v-if="status !== 'JOIN NOW'">
                    <a class="text_link tncText" :href="clientPortalSettings.tncLink" target="_blank" data-testid="tnc">
                      {{ $t('promotion.tradingContest.tnc') }} >
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="prize_wrapper">
          <div class="prize_wrapper_header">
            <h4>{{ $t('promotion.tradingContest.prizes') }}</h4>
            <div class="more" v-if="prizeList2?.length" @click="morePrizes()">
              {{ $t('promotion.tradingContest.more') }}
              <span><i class="el-icon-arrow-right"></i></span>
            </div>
          </div>

          <div class="prizes">
            <el-row :gutter="30" class="prize_flex_center">
              <el-col :sm="6" :xs="12" v-for="(item, i) in prizeList1" :key="i">
                <div class="prize_box">
                  <div class="ranking">{{ formatRank(parseInt(item.rank)) }}</div>
                  <div class="title">USD${{ numberFormatter(item.amount) }}</div>
                  <div class="ribbon">
                    <img src="@/assets/images/promotion/tradingContest/prize_ribbon.png" />
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-card>

      <Leaderboard v-if="tradingContestInfo" :tradingContestInfo="tradingContestInfo"></Leaderboard>
    </div>

    <PrizePopup
      :prizePopupVisible.sync="prizePopupVisible"
      :prizeList1="prizeList1"
      :prizeList2="prizeList2"
    ></PrizePopup>

    <OpenAccountModal
      :openAccountModalVisible.sync="openAccountModalVisible"
      :tradingContestInfo="tradingContestInfo"
      @openAccountSuccess="showOneStepAwayModal"
    >
    </OpenAccountModal>

    <OneStepAwayModal
      :oneStepAwayModalVisible.sync="oneStepAwayModalVisible"
      :minDepositAmount="numberFormatter(tradingContestInfo.minimumDepositInUsd)"
    ></OneStepAwayModal>

    <EligibleAccountModal
      :eligibleAccountModalVisible.sync="eligibleAccountModalVisible"
      :tradingContestInfo="tradingContestInfo"
      @getDetails="getDetails"
    ></EligibleAccountModal>
  </div>
</template>

<script>
import Step from './Step.vue';
import TermsAndConditions from './TermAndConditions.vue';
import PrizePopup from './PrizePopup.vue';
import Leaderboard from './Leaderboard.vue';
import TradingContestButton from './Button.vue';
import OneStepAwayModal from './OneStepAwayModal.vue';
import OpenAccountModal from './OpenAccountModal.vue';
import EligibleAccountModal from './EligibleAccountModal.vue';
import AccountDetailBox from './AccountDetailBox.vue';
import { mapState } from 'vuex';
import { formatDateTime } from '@/util/time';
import { getOrdinal } from '@/util/ordinal';
import { apiCampaignOpt } from '@/resource';

export default {
  name: 'FundAccount',
  components: {
    AccountDetailBox,
    Step,
    TradingContestButton,
    TermsAndConditions,
    Leaderboard,
    PrizePopup,
    OneStepAwayModal,
    OpenAccountModal,
    EligibleAccountModal
  },
  mixins: [],
  data() {
    return {
      isAgreeTnc: false,
      showCheckboxError: false,
      prizePopupVisible: false,
      openAccountModalVisible: false,
      eligibleAccountModalVisible: false,
      oneStepAwayModalVisible: false
    };
  },
  computed: {
    ...mapState('promotion/tradingContest', [
      'tradingContestInfo',
      'tradingContestAcc',
      'tradingContestWithdrawRestrict'
    ]),
    campaignID() {
      return this.$route.params.campaignId || null;
    },
    status() {
      return this.tradingContestInfo.status;
    },
    lang() {
      return this.$store.state.common.lang;
    },
    clientPortalSettings() {
      const clientPortalSettings = this.tradingContestInfo?.clientPortalSettings || {};

      return {
        ...this.tradingContestInfo.clientPortalSettings,
        document:
          clientPortalSettings.document !== null ? `${window.location.origin}/api${clientPortalSettings.document}` : ''
      };
    },
    startDate() {
      return formatDateTime(this.tradingContestInfo.startDate, 'DD/MM/YYYY');
    },
    endDate() {
      return formatDateTime(this.tradingContestInfo.endDate, 'DD/MM/YYYY');
    },
    campaignStartTimestamp() {
      const currentTime = new Date().getTime() + 1 * 60 * 1000; // add 1 min so the 'display without seconds' wont look weird
      if (
        this.tradingContestInfo &&
        this.tradingContestInfo.startDateAdd30mins &&
        currentTime < this.tradingContestInfo.startDateAdd30mins
      ) {
        return this.tradingContestInfo.startDateAdd30mins;
      }
    },
    prizeList() {
      return Object.keys(this.tradingContestInfo)?.length ? this.tradingContestInfo.prizeList : [];
    },
    prizeList1() {
      return this.prizeList.length ? this.prizeList.slice(0, 3) : [];
    },
    prizeList2() {
      return this.prizeList.length ? this.prizeList.slice(3) : [];
    },
    showButton() {
      const currentTime = new Date().getTime();
      if (this.tradingContestInfo && this.tradingContestInfo.endDate) {
        return currentTime < this.tradingContestInfo.endDate;
      }
      return true;
    }
  },
  mounted() {
    this.getDetails();
  },
  methods: {
    optOutNow() {
      apiCampaignOpt(this.campaignID, false)
        .then(resp => {
          if (resp.data.data) {
          } else {
            this.showError();
          }
        })
        .catch(err => {
          this.showError();
        });
      this.showDialog = false;
    },
    morePrizes() {
      this.prizePopupVisible = true;
    },
    numberFormatter(value) {
      return value ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : null;
    },
    showOneStepAwayModal() {
      this.getDetails();
      this.oneStepAwayModalVisible = true;
    },
    getDetails() {
      this.$store.dispatch('promotion/tradingContest/actionTradingContestParticipantInfo', {
        campaignId: this.campaignID,
        languageCode: this.lang
      });
    },
    formatRank(value) {
      return getOrdinal(value);
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/css/pages/tradingContest.scss';
@import '@/assets/css/components/promotion/clientPortalSettings.scss';

.tncText {
  color: $sky-blue;
}
</style>
