<template>
  <div class="selection" :class="[calSelectType, calError, ...calCustomClasses]">
    <div class="selection-title" :class="{ 'on-focus': isFocus }">
      <p v-if="title">{{ title }}</p>
      <slot v-else name="title"></slot>
    </div>
    <el-select
      v-bind="$attrs"
      v-on="$listeners"
      v-model="selectedOption"
      :filterable="calFilterable"
      :clearable="calClearable"
      :class="{ 'has-value': selectedOption && calClearable, 'has-option': !!options.length }"
      :size="calSize"
      :popper-class="`dropdown-style-${calSize}`"
      @focus="isFocus = true"
      @blur="isFocus = false"
      :data-testid="calDataTestId"
    >
      <template #empty>
        <slot name="selectEmptyContent"></slot>
      </template>
      <template #prefix>
        <slot name="selectPrefix"></slot>
      </template>

      <div v-if="calGroup">
        <el-option-group
          v-for="(group, groupIndex) in options"
          :key="groupIndex"
          :label="group.label"
          :disabled="group.disabled"
        >
          <el-option
            v-for="(item, index) in group.options"
            :key="index"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
            :data-testid="optionDataTestId(item, group.label)"
          >
            <slot name="optionContent" :optionData="item"></slot>
          </el-option>
        </el-option-group>
      </div>

      <div v-else>
        <el-option
          v-for="(item, index) in calOptions"
          :key="index"
          :label="item.label"
          :value="item.value"
          :disabled="item.disabled"
          :data-testid="optionDataTestId(item)"
        >
          <slot name="optionContent" :optionData="item"></slot>
        </el-option>
      </div>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'PugSelection',
  model: {
    prop: 'value',
    event: 'returnModel'
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    value: {
      type: String | Array | Number,
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    },
    filterable: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    selectType: {
      type: String,
      default: 'outline' // outline, underline
    },
    customClasses: {
      type: Array | String,
      default: ''
    },
    error: {
      type: Boolean,
      default: false
    },
    dataTestId: {
      type: String | Number,
      default: 'selection'
    },
    optionDataTestId: {
      type: Function,
      default: (item, groupName) => `select_${groupName ? groupName + '_' : ''}${item.value}`
    },
    size: {
      type: String,
      default: '' // small
    }
  },
  data() {
    return {
      isFocus: false,
      elSelectRef: null
    };
  },
  computed: {
    calSize() {
      return this.size;
    },
    calDataTestId() {
      return this.dataTestId;
    },
    selectedOption: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('returnModel', newValue);
        return newValue;
      }
    },
    calTitle() {
      return this.title;
    },
    calOptions() {
      return [...this.options];
    },
    calGroup() {
      return this.calOptions.some(item => item?.options?.length > 0);
    },
    calFilterable() {
      return this.filterable;
    },
    calClearable() {
      return this.clearable;
    },
    calSelectType() {
      return this.selectType;
    },
    calError() {
      return this.error ? 'error' : '';
    },
    calCustomClasses() {
      return typeof this.customClasses === 'string' ? [this.customClasses] : [...this.customClasses];
    }
  }
};
</script>

<style lang="scss" scoped>
.selection {
  &-title {
    font-weight: 400;
    font-size: 16px;
    color: $pug-gray-title;
  }

  &.error {
    .selection-title {
      color: $pug-red;
      &.on-focus {
        color: $pug-red;
      }
    }
    /deep/ .el-select {
      .el-input__inner {
        color: $pug-red;
        &:focus {
          color: $pug-red;
        }
      }
    }
  }

  /deep/ .el-select {
    &:hover.has-value {
      .el-input__suffix {
        .el-input__suffix-inner {
          .el-input__icon {
            &:nth-child(1) {
              display: none !important;
            }
          }
        }
      }
    }
    &.has-option {
      .el-input__suffix {
        display: block;
        .el-input__suffix-inner {
          .el-input__icon {
            display: inline-block !important;
          }
        }
      }
    }

    .el-input__suffix {
      @include rtl-sass-prop(right, left, 14px);
      .el-input__suffix-inner {
        .el-input__icon {
          // display: inline-block !important;
          display: none !important;
          font-size: 18px;
          font-weight: 900;
          color: $pug-black;
        }
      }
    }

    .el-input__inner {
      border-radius: 10px;
      font-size: 16px;
      height: 48px;
      font-weight: 700;
      color: $pug-black;
      background: none;
      background-color: #ffffff00;
      &::placeholder {
        font-weight: 400;
        color: $pug-gray;
        font-size: 16px;
      }
      &:focus {
        font-size: 16px;
        font-weight: 400;
        color: $pug-black;
      }
    }

    .el-input.is-disabled {
      .el-input__inner {
        font-weight: 400;
        color: $pug-gray !important;
        cursor: url('../../assets/images/common/not-allowed.png'), not-allowed;
      }
      .el-input__icon {
        cursor: url('../../assets/images/common/not-allowed.png'), not-allowed;
      }
      .el-input__suffix {
        display: none;
      }
    }

    &.el-select--small {
      .el-input__inner {
        font-size: 12px;
        height: 40px;
        &::placeholder {
          font-size: 12px;
        }
      }

      .el-input__suffix {
        @include rtl-sass-prop(right, left, 14px);
        .el-input__suffix-inner {
          .el-input__icon {
            font-size: 10px;
          }
        }
      }
    }
  }
}

.selection.outline {
  &.error {
    /deep/ .el-select {
      .el-input__inner {
        border: 1px solid $pug-red;
      }
    }
  }

  /deep/ .el-select {
    .el-input__inner {
      border: 1px solid $pug-pray-border;
      padding: 16px 8px;
      &:focus {
        border: 1px solid $pug-blue-hover;
      }
    }
    .el-input.is-disabled {
      .el-input__inner {
        border: 1px solid $pug-gray-opacity-40;
        background-color: $pug-gray-opacity-10;
      }
    }

    &.el-select--small {
      .el-input__inner {
        padding: 16px;
      }
    }
  }
}

.selection.underline {
  &.error {
    /deep/ .el-select {
      .el-input__inner {
        border: none;
        border-bottom: 1px solid $pug-red;
      }
    }
  }

  /deep/ .el-select {
    .el-input__inner {
      border-radius: 0px;
      border: none;
      border-bottom: 1px solid $pug-pray-border;
      padding: 0;
      height: fit-content;
      line-height: 30px;
      &:focus {
        border: none;
        border-bottom: 1px solid $pug-blue-hover;
      }
    }
    .el-input__suffix {
      @include rtl-sass-prop(right, left, 0px);
    }
    .el-input.is-disabled {
      .el-input__inner {
        border: none;
        border-bottom: 1px solid $pug-gray-opacity-40;
      }
    }
  }
}
</style>
