<template>
  <div class="steps">
    <el-row type="flex" justify="space-between">
      <el-col v-for="step in stepList" :key="step.step">
        <div class="relative">
          <div class="step-button">
            <button>
              {{ $t('promotion.tradingContest.step', { number: step.step }) }}
            </button>
          </div>

          <div class="line_wrapper">
            <span class="line_left"></span>
            <span class="line_right"></span>
          </div>
        </div>

        <div class="description">{{ step.desc }}</div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'TradingContestStep',
  props: {
    amount: {
      type: String,
      default: ''
    },
    sortBy: {
      type: String,
      default: ''
    }
  },
  data() {
    return {};
  },
  computed: {
    mechanics() {
      switch (this.sortBy) {
        case 'Total Profit (USD)':
          return this.$t('promotion.tradingContest.column.profit', {
            usedSymbol: this.$t('promotion.tradingContest.column.usd')
          });
        default:
          return this.$t('promotion.tradingContest.column.profit', { usedSymbol: '%' });
      }
    },
    stepList() {
      return [
        {
          step: 1,
          desc: this.$t('promotion.tradingContest.step1Desc')
        },
        {
          step: 2,
          desc: this.$t('promotion.tradingContest.step2Desc', { amount: this.amount })
        },
        {
          step: 3,
          desc: this.$t('promotion.tradingContest.step3Desc', { mechanics: this.mechanics })
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/tradingContest/step.scss';
</style>
