<template>
  <div id="tradingContest" class="promotion">
    <p class="pageHeader">{{ clientPortalSettings.pageTitle }}</p>

    <div class="content-box">
      <div class="inner">
        <div class="inner-container">
          <FundAccount />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PromotionTemplate from '@/components/promotion/common/PromotionTemplate.vue';
import FundAccount from '@/components/promotion/tradingContest/FundAccount.vue';
import { mapState } from 'vuex';

export default {
  name: 'tradingContest',
  components: {
    PromotionTemplate,
    FundAccount
  },
  computed: {
    ...mapState('promotion/tradingContest', [
      'tradingContestInfo',
      'tradingContestAcc',
      'tradingContestWithdrawRestrict'
    ]),
    clientPortalSettings() {
      return {
        ...this.tradingContestInfo.clientPortalSettings
      };
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/promotion/common/promotion-base-style.scss';
@import '@/assets/css/pages/tradingContest.scss';
@import '@/assets/css/pages/promotion.scss';

.pageHeader {
  font-size: 24px;
  font-weight: bold;
  margin-left: 50px;
  margin-top: 36px;
}
</style>
