import { countryCodeEnum } from './country'

export const highRiskCountry = [
    countryCodeEnum.AFGHANISTAN,
    countryCodeEnum.BELARUS,
    countryCodeEnum.BURUNDI,
    countryCodeEnum.CENTRAL_AFRICAN_REPUBLIC,
    countryCodeEnum.CUBA,
    countryCodeEnum.CONGO_DEMOCRATIC,
    countryCodeEnum.ERITREA,
    countryCodeEnum.GUINEA,
    countryCodeEnum.GUINEA_BISSAU,
    countryCodeEnum.HAITI,
    countryCodeEnum.IRAN,
    countryCodeEnum.IRAQ,
    countryCodeEnum.LEBANON,
    countryCodeEnum.LIBERIA,
    countryCodeEnum.LIBYA,
    countryCodeEnum.MALI,
    countryCodeEnum.MYANMAR,
    countryCodeEnum.NICARAGUA,
    countryCodeEnum.NORTH_KOREA,
    countryCodeEnum.SOMALIA,
    countryCodeEnum.SOUTH_SUDAN,
    countryCodeEnum.SUDAN,
    countryCodeEnum.SYRIA,
    countryCodeEnum.TUNISIA,
    countryCodeEnum.UKRAINE,
    countryCodeEnum.VENEZUELA,
    countryCodeEnum.YEMEN,
    countryCodeEnum.ZIMBABWE,
]