<template>
  <el-dialog :visible.sync="visible" top="0" class="one_step_away_modal">
    <div class="content text-center">
      <img src="@/assets/images/dialog/dialog_true.png" alt />
      <div class="title">{{ $t('promotion.tradingContest.oneStepAway') }}</div>
      <p>{{ $t('promotion.tradingContest.oneStepAwayDesc') }}</p>
      <p v-html="$t('promotion.tradingContest.oneStepAwayDesc2', { amount: minDepositAmount })"></p>
    </div>
    <div class="btn_box">
      <el-button class="secondary" @click="handleClose">{{ $t('promotion.tradingContest.cancel') }}</el-button>
      <el-button class="primary" @click="depositNow()">{{ $t('promotion.tradingContest.deposit') }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'OneStepAwayModal',
  props: {
    oneStepAwayModalVisible: Boolean,
    minDepositAmount: String
  },
  data() {
    return {
      visible: false
    };
  },
  watch: {
    oneStepAwayModalVisible(bool) {
      this.visible = bool;
    },
    visible(bool) {
      this.$emit('update:oneStepAwayModalVisible', bool);
    }
  },
  methods: {
    handleClose() {
      this.visible = !this.visible;
    },
    depositNow() {
      this.$router.push({ path: '/depositFunds' });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/tradingContest.scss';
</style>
