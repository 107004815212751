import { countryCodeEnum } from './country'

export const islamicCountry = [
  countryCodeEnum.UNITED_ARAB_EMIRATES,
  countryCodeEnum.SAUDI_ARABIA,
  countryCodeEnum.TURKEY,
  countryCodeEnum.EGYPT,
  countryCodeEnum.PALESTINIAN,
  countryCodeEnum.KUWAIT,
  countryCodeEnum.INDIA,
  countryCodeEnum.MALAYSIA,
  countryCodeEnum.INDONESIA,
  countryCodeEnum.ALGERIA,
  countryCodeEnum.BAHRAIN,
  countryCodeEnum.IRAN,
  countryCodeEnum.IRAQ,
  countryCodeEnum.ISRAEL,
  countryCodeEnum.JORDAN,
  countryCodeEnum.LEBANON,
  countryCodeEnum.LIBYA,
  countryCodeEnum.MOROCCO,
  countryCodeEnum.OMAN,
  countryCodeEnum.QATAR,
  countryCodeEnum.SUDAN,
  countryCodeEnum.SYRIA,
  countryCodeEnum.TUNISIA,
  countryCodeEnum.YEMEN,
  countryCodeEnum.PAKISTAN,
  countryCodeEnum.AFGHANISTAN,
  countryCodeEnum.BANGLADESH,
]
